import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html'
})
export class PricingComponent implements OnInit {
  public showpage: boolean = false;
  public showSignUpBtn: boolean = true;
  public showUpgradeBtn: boolean = false;
  public currentPlan: any = "";
  public signUpURL: any = "";
  public hasPastDue: boolean = true;
  public hasFutSubs: boolean = true;
  public isValidEntitlement: boolean = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    let userDetails = null;
    this.userService.getUserDeatails().subscribe(
      success => {
        userDetails = success;
        this.showpage = true;
        this.showSignUpBtn = false;
        for (var i = 0; i < userDetails.entitlements.length; i++) {
          if (userDetails.entitlements[i].product == "MIPro") {
            if(userDetails.entitlements[i].status == 'SUSPENDED' || userDetails.entitlements[i].status == 'CANCELLED'){
              this.isValidEntitlement = false;
            }
            this.currentPlan = userDetails.entitlements[i].plan;
            let recurlyAccountId = userDetails.entitlements[i].recurlyAccountId;
            if(recurlyAccountId){
              let planId = this.currentPlan;
              if (planId == 'MIPro_PayAsYouGo' || planId == 'MIPro_Enterprise' || planId == 'MIPro_2500_PayAsYouGo' || planId == 'MIPro_5K_Enterprise') {
                this.getUserAccountDetails();
              }
              else{
                this.hasPastDue = false;
                this.hasFutSubs = false;
                this.checkAccount();
              }
            }
            else{
              //alert("User without recurly account id.");
              window.location.href = '/signout';
            }
          }
        }
        if (this.currentPlan !== "") {
          this.showUpgradeBtn = true;
        }
      },
      error => {
        window.location.href = "/signin";
      }
    );
    this.resourceService.getSignupUrl().subscribe(
      success => {
        this.signUpURL = success.signUpURL;
      }
    );
  };
  checkAccount= function(){
    this.userService.getUserAccountDetailsById().subscribe(
      success => {},
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  getUserAccountDetails = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  upgradePlan = function (planName) {
    var upgradePlanURL = this.signUpURL + "upgrade/mipro?plan=" + planName;
    window.location.href = upgradePlanURL;
  };
}
