import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../service/user.service';
import { ResourceService } from '../service/resource.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { KeyValue } from '@angular/common';
declare const recurly: any;

@Component({
  selector: 'myaccount',
  templateUrl: './myaccount.component.html',
  providers: [NgbModalConfig, NgbModal]
})
export class MyaccountComponent implements OnInit {
  public showpage: boolean = false;
  public firstName: string = '';
  public companyName: string = '';
  public lastName: string = '';
  public email: string = '';
  public userSubs: any = [];
  public currentPlan: string = '';
  public planDetails: any;
  public hasPastDue: boolean = true;
  public hasFutSubs: boolean = true;
  public isSuspendedEntitlement: boolean = false;
  public isCancelledEntitlement: boolean = false;
  public isFreeExpired: boolean = false;
  public subscriptionEndDate: string = '';
  public isPlanExpired: boolean = false;
  public invoiceArr = [];
  public noInvoiceflag: boolean = false;
  public isPaymentErr: boolean = false;
  public isSubsActiveErr: boolean = false;
  public isPaymentModalErr: boolean = false;
  public isPaymentSuccess: boolean = false;
  public isPaymentModalSuccess: boolean = false;
  public paymentErrMsg: string = '';
  public paymentModalErrMsg: string = '';
  public showCCForm: boolean = false;
  public cardNumber: string = '';
  public expirationDate: string = '';
  public displayName: string = '';
  public billFirstName: string = '';
  public billLastName: string = '';
  public cardType: string = '';
  public addressLine1: string = '';
  public addressLine2: string = '';
  public city: string = '';
  public usStateMap = {AL: "Alabama", AK: "Alaska", AZ: "Arizona", AR: "Arkansas", CA: "California", CO: "Colorado", CT: "Connecticut", DE: "Delaware", DC: "District of Columbia", FL: "Florida", GA: "Georgia", HI: "Hawaii", ID: "Idaho", IL: "Illinois", IN: "Indiana", IA: "Iowa", KS: "Kansas", KY: "Kentucky", LA: "Louisiana", ME: "Maine", MD: "Maryland", MA: "Massachusetts", MI: "Michigan", MN: "Minnesota", MS: "Mississippi", MO: "Missouri", MT: "Montana", NE: "Nebraska", NV: "Nevada", NH: "New Hampshire", NJ: "New Jersey", NM: "New Mexico", NY: "New York", NC: "North Carolina", ND: "North Dakota", OH: "Ohio", OK: "Oklahoma", OR: "Oregon", PA: "Pennsylvania", RI: "Rhode Island", SC: "South Carolina", SD: "South Dakota", TN: "Tennessee", TX: "Texas", UT: "Utah", VT: "Vermont", VA: "Virginia", WA: "Washington", WV: "West Virginia", WI: "Wisconsin", WY: "Wyoming"};
  public stateMap: any = ['Alabama'];
  public stateprovince: string = 'Alabama';
  public stateName: string = 'Alabama';
  public countryMap: any = {US: 'United States'};
  public countryCode: string = 'US';
  public postalCode: string = '';
  public showConfirmAddr: boolean = false;
  public showConfirmModalAddr: boolean = false;
  public verifiedAddrRes: any;
  public verifiedAddrModalRes: any;
  public confirmAddrRadio: any = null;
  public confirmAddrModalRadio: any = null;

  @ViewChild('enterCardModal', { static: false }) enterCardModalEl: ElementRef;
  @ViewChild('expiredModal', { static: false }) expiredModalEl: ElementRef;
  public enterCardModalRef: any;
  @ViewChild('cancelConfirmModal', { static: false }) cancelConfirmModalEl: ElementRef;
  public cancelConfirmModalRef: any;
  public isCancelSubsErr: boolean = false;
  public isCancelSubsSuccess: boolean = false;
  public isCancelProcessing: boolean = false;
  public isSuspenProcessing: boolean = false;
  public isSuspenSubsSuccess: boolean = false;
  public isSuspenSubsErr: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, config: NgbModalConfig, private modalService: NgbModal, private resourceService: ResourceService) {
    config.backdrop = 'static';
    config.keyboard = false;
  };
  ngOnInit() {
    window.scroll(0, 0);
    this.loadCountryMap();
    this.planDetails = {
      name: "",
      quota: 0
    };
    let userDetails = null;
    this.userService.getUserDeatails().subscribe(
      success => {
        userDetails = success;
        this.getUserProfile();
        for (let i = 0; i < userDetails.entitlements.length; i++) {
          if (userDetails.entitlements[i].product == 'MIPro') {
            this.userSubs[0] = userDetails.entitlements[i];
            this.currentPlan = this.userSubs[0].plan;
            if(userDetails.entitlements[i].status == 'SUSPENDED'){
              this.isSuspendedEntitlement = true;
            }
            if(userDetails.entitlements[i].status == 'CANCELLED'){
              this.isCancelledEntitlement = true;
            }
          }
        }
        if(this.isCancelledEntitlement || this.isSuspendedEntitlement){
          setTimeout(() =>
            this.modalService.open(this.expiredModalEl, { ariaLabelledBy: 'modal-basic-title' })
          );
        }
        else if(userDetails.subscriptions.mipro.subscriptionState != 'active'){
          let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Non", "Dec"];
          let isoDate = new Date(userDetails.subscriptions.mipro.endDate);
          this.subscriptionEndDate = monthNames[isoDate.getMonth()] + " " + isoDate.getDate() + ", " + isoDate.getFullYear();
          if (userDetails.subscriptions.mipro.gPlanid == 'MIPro_Premium') {
            this.isFreeExpired = true;
          }
          else {
            this.isPlanExpired = true;
          }
          setTimeout(() =>
            this.modalService.open(this.expiredModalEl, { ariaLabelledBy: 'modal-basic-title' })
          );
        }
        if (this.userSubs.length !== 0){
          let recurlyAccountId = this.userSubs[0].recurlyAccountId;
          if(recurlyAccountId){
            this.showpage = true;
            let planId = this.userSubs[0].plan;
            if (planId == 'MIPro_PayAsYouGo' || planId == 'MIPro_Enterprise' || planId == 'MIPro_2500_PayAsYouGo' || planId == 'MIPro_5K_Enterprise') {
              this.getUserAccountDetails();
            }
            else{
              this.hasPastDue = false;
              this.hasFutSubs = false;
            }
            this.getUserInvoices();
            let recurlyConfig = localStorage.getItem("recurlyConfig");
            if(!recurlyConfig){
              this.resourceService.getRecurlyConfig().subscribe(
                success => {
                  localStorage.setItem("recurlyConfig", success.recurlyConfig);
                }
              );
            }
          }
          else{
            window.location.href = '/signout';
          }
        }
        this.userService.getPlanDetails().subscribe(
          success => {
            this.planDetails = success;
          },
          error => {
            if(error.error.error == 'Cookie tempered'){
              window.location.href = '/signout';
            }
          }
        );
      },
      error => {
        window.location.href = "/";
      }
    );
  };
  loadCountryMap = function(){
    this.resourceService.getCountries().subscribe(
      success => {
        this.countryMap = success;
      }
    );
  };
  loadStateMap = function(countryCode){
    this.resourceService.getStates(countryCode).subscribe(
      success => {
        this.stateMap = success.states;
        this.stateName = this.stateprovince;
      }
    );
  };
  getUserAccountDetails = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
          if (success.data[0].billing_info != null) {
            this.cardNumber = '**** **** **** '+success.data[0].billing_info.payment_method.last_four;
            this.expirationDate = success.data[0].billing_info.payment_method.exp_month +'/'+ success.data[0].billing_info.payment_method.exp_year;
            this.billFirstName = success.data[0].billing_info.first_name;
            this.billLastName = success.data[0].billing_info.last_name;
            this.displayName = success.data[0].billing_info.first_name + ' ' +success.data[0].billing_info.last_name;
            this.cardType = success.data[0].billing_info.payment_method.card_type.toUpperCase();
            this.addressLine1 = success.data[0].billing_info.address.street1;
            this.city = success.data[0].billing_info.address.city;
            this.stateprovince = success.data[0].billing_info.address.region;
            this.countryCode = success.data[0].billing_info.address.country;
            if(this.countryCode == 'US'){
              this.stateprovince = this.usStateMap[success.data[0].billing_info.address.region];
            }
            this.postalCode = success.data[0].billing_info.address.postal_code;
            this.loadStateMap(this.countryCode);
          }
          else{
            this.loadStateMap(this.countryCode);
            setTimeout(() =>
              this.enterCardModalRef = this.modalService.open(this.enterCardModalEl, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
            );
          }
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  checkPastDue = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  numberWithCommas = function(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  getUserProfile = function(){
    this.userService.getUserProfile().subscribe(
      success => {
        this.firstName = success.firstName;
        this.lastName = success.lastName;
        this.email = success.email;
        this.companyName = success.companyName;
      }
    );
  };
  getUserInvoices = function(){
      this.userService.getUserInvoices().subscribe(
          success => {
              if(success.data.length == 0){
                  this.noInvoiceflag = true;
              }
              else {
                  for(let i=0; i<success.data.length; i++){
                    if(success.data[i].total !== 0){
                      let invoiceObj = {};
                      invoiceObj['state'] = success.data[i].state;
                      invoiceObj['id'] = success.data[i].id;
                      invoiceObj['number'] = success.data[i].number;
                      invoiceObj['created_at'] = this.formatUSDateStr(success.data[i].created_at);
                      if(success.data[i].closed_at){
                        invoiceObj['closed_at'] = this.formatUSDateStr(success.data[i].closed_at);
                      }
                      else{
                        invoiceObj['closed_at'] = 'N/A';
                      }
                      invoiceObj['total'] = this.formatCurrency(success.data[i].currency, success.data[i].total);
                      invoiceObj['paid'] = this.formatCurrency(success.data[i].currency, success.data[i].paid);
                      this.invoiceArr.push(invoiceObj);
                    }
                  }
                  this.invoiceArr.sort(function (a, b) {
                    return b.number - a.number;
                  });
                  if(this.invoiceArr.length == 0){
                    this.noInvoiceflag = true;
                  }
              }
          },
          error => {
            if(error.error.error == 'Cookie tempered'){
              window.location.href = '/signout';
            }
          }
      );
  };
  formatUSDateStr = function(dateStr){
    let dateObj = new Date(dateStr);
    return (dateObj.getMonth()+1)+'-'+dateObj.getDate()+'-'+dateObj.getFullYear();
  };
  formatCurrency = function(currency, amount){
    let amountStr = String(amount);
    if(currency == 'USD'){
      amountStr = '$'+amountStr;
    }
    let amountArr = amountStr.split('.');
    if(amountArr.length == 1){
      amountStr += '.00';
    }
    else if(amountArr[1].length > 2){
      amountStr = amountArr[0]+'.'+amountArr[1].substring(0, 2);
    }
    return amountStr;
  };
  showInvoice = function(invoiceNo) {
	  document.getElementById(invoiceNo).setAttribute("disabled", "disabled");
    var reqUrl = '/user/invoice/' +invoiceNo;
    var req = new XMLHttpRequest();
    req.open("GET", reqUrl, true);
    req.responseType = "blob";
    req.onload = function (event) {
      if(req.status !== 403){
        var blob = req.response;
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "MIPro_Invoice_" +invoiceNo+ ".pdf";
        link.click();
        document.getElementById(invoiceNo).removeAttribute("disabled");
      }
      else{
        window.location.href = '/signout';
      }
    };
    req.send();
  };
  showPaymentForm = function(){
    if(this.countryCode !== 'US'){
      $('#validateAddressBtn').hide();
      $('#cancelPaymentBtn').hide();
      $('#submitPaymentBtn').show();
      $('#reEnterAddrBtn').show();
    }
    this.showCCForm = true;
    this.isSubsActiveErr = false;
    let cardEleHtml = $('#recurly-elements').html();
    if(cardEleHtml !== ''){
      $('#recurly-elements').html('');
    }
    $('#recurly-form').show();
    recurly.configure({
      publicKey: localStorage.getItem("recurlyConfig"),
      required: [ 'cvv' ]
    });
    const elements = recurly.Elements();
    const cardElement = elements.CardElement({​
      inputType: 'mobileSelect',
      style: {​
        fontSize: '1em',
        padding: '10px 12px',
        height: '40px',
        placeholder: {​
          color: 'gray !important',
          fontWeight: 'bold',
          content: {​
            number: 'Card number',
            cvv: 'CVV'
          }​
        }​,
        invalid: {​
          fontColor: 'red'
        }​
      }​
    }​);
    cardElement.attach('#recurly-elements');
    let fields = {​
      'first_name': 'First Name',
      'last_name': 'Last Name',
      'address1': 'Address',
      'address2': 'Address 2',
      'city': 'City',
      'state': 'State',
      'postal_code': 'Postal Code',
      'country': 'Country',
      'number': 'Credit Card Number',
      'month': 'Credit Card Month',
      'year': 'Credit Card Year',
      'cvv': 'CVV'
    }​;
    let that = this;
    document.querySelector('#recurly-form').addEventListener('submit', function(event){
      const form = this;
      event.preventDefault();​
      if(that.confirmAddrRadio == null && that.countryCode == 'US'){
        that.paymentErrMsg = 'Please select a corrected address.';
        that.isPaymentErr = true;
        document.getElementById("submitPaymentBtn").removeAttribute("disabled");
        document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
        document.querySelector("#paymentNotification").scrollIntoView();
        return false;
      }
      document.getElementById("submitPaymentBtn").setAttribute("disabled", "disabled");
      document.getElementById("reEnterAddrBtn").setAttribute("disabled", "disabled");
      that.paymentErrMsg = '';
      that.isPaymentErr = false;
      recurly.token(elements, form, function(err, token){
        if(err){
          let failedMsgArr = [];
          if(err.name === 'api-error' || err.name === 'validation'){
            for (let i = 0; i < err.details.length; i++) {​
              failedMsgArr.push(`${​fields[err.details[i].field]}​ ${​err.details[i].messages[0]}​`);
            }​
          }​
          else {​
            failedMsgArr.push(err.message);
          }
          for (let i = 0; i < failedMsgArr.length; i++) {
            if(i !== 0){
              if(i === failedMsgArr.length -1){
                that.paymentErrMsg += ' and ';
              }
              else{
                that.paymentErrMsg += ', ';
              }
            }
            that.paymentErrMsg += failedMsgArr[i];
          }​
          that.paymentErrMsg += '.';
          that.isPaymentErr = true;
          document.getElementById("submitPaymentBtn").removeAttribute("disabled");
          document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
          document.querySelector("#paymentNotification").scrollIntoView();
        ​}
        else{
          that.userService.updatePaymentInfo(token.id).subscribe(
            success => {
              that.isPaymentSuccess = true;
              that.isPaymentErr = false;
              document.querySelector("#paymentNotification").scrollIntoView();
              if(that.hasPastDue || that.hasFutSubs){
                that.checkPastDue();
              }
              that.cardNumber = '**** **** **** '+success.payment_method.last_four;
              that.expirationDate = success.payment_method.exp_month +'/'+ success.payment_method.exp_year;
              that.billFirstName = success.first_name;
              that.billLastName = success.last_name;
              that.displayName = success.first_name + ' ' +success.last_name;
              that.cardType = success.payment_method.card_type.toUpperCase();
              that.addressLine1 = success.address.street1;
              that.addressLine2 = success.address.street2;
              that.city = success.address.city;
              that.stateprovince = success.address.region;
              that.countryCode = success.address.country;
              if(that.countryCode == 'US'){
                that.stateprovince = that.usStateMap[success.address.region];
              }
              that.postalCode = success.address.postal_code;
              that.loadStateMap(that.countryCode);
              setTimeout(() => {
                document.getElementById("submitPaymentBtn").removeAttribute("disabled");
                document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
                that.closePaymentForm(false);
              }, 3000);
            },
            error => {
              if(error.error.error == 'Cookie tempered'){
                window.location.href = '/signout';
                return;
              }
              document.getElementById("submitPaymentBtn").removeAttribute("disabled");
              document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
              that.paymentErrMsg = 'Failed to update. Please try again.';
              if(error.error){
                that.paymentErrMsg = error.error.error.message;
              }
              that.isPaymentErr = true;
              document.querySelector("#paymentNotification").scrollIntoView();
            }
          );
        }
      });​
    });
  };
  openPaymentForm = function(){
    this.isPaymentFormOpen = true;
    $('#recurly-modal-form').show();
    let cardEleHtml = $('#recurly-modal-elements').html();
    if(cardEleHtml == ''){
      recurly.configure({
        publicKey: localStorage.getItem("recurlyConfig"),
        required: [ 'cvv' ]
      });
      const elements = recurly.Elements();
      const cardElement = elements.CardElement({​
        inputType: 'mobileSelect',
        style: {​
          fontSize: '1em',
          padding: '10px 12px',
          height: '40px',
          placeholder: {​
            color: 'gray !important',
            fontWeight: 'bold',
            content: {​
              number: 'Card number',
              cvv: 'CVV'
            }​
          }​,
          invalid: {​
            fontColor: 'red'
          }​
        }​
      }​);
      cardElement.attach('#recurly-modal-elements');
      let fields = {​
        'first_name': 'First Name',
        'last_name': 'Last Name',
        'address1': 'Address',
        'address2': 'Address 2',
        'city': 'City',
        'state': 'State',
        'postal_code': 'Postal Code',
        'country': 'Country',
        'number': 'Credit Card Number',
        'month': 'Credit Card Month',
        'year': 'Credit Card Year',
        'cvv': 'CVV'
      }​;
      let that = this;
      document.querySelector('#recurly-modal-form').addEventListener('submit', function(event){
        const form = this;
        event.preventDefault();​
        if(that.confirmAddrModalRadio == null && that.countryCode == 'US'){
          that.paymentModalErrMsg = 'Please select a corrected address.';
          that.isPaymentModalErr = true;
          document.getElementById("submitPaymentModalBtn").removeAttribute("disabled");
          document.getElementById("reEnterAddrModalBtn").removeAttribute("disabled");
          document.querySelector("#modal-basic-title").scrollIntoView();
          return false;
        }
        document.getElementById("submitPaymentModalBtn").setAttribute("disabled", "disabled");
        document.getElementById("reEnterAddrModalBtn").setAttribute("disabled", "disabled");
        that.paymentModalErrMsg = '';
        that.isPaymentModalErr = false;
        recurly.token(elements, form, function(err, token){
          if(err){
            let failedMsgArr = [];
            if(err.name === 'api-error' || err.name === 'validation'){
              for (let i = 0; i < err.details.length; i++) {​
                failedMsgArr.push(`${​fields[err.details[i].field]}​ ${​err.details[i].messages[0]}​`);
              }​
            }​
            else {​
              failedMsgArr.push(err.message);
            }
            for (let i = 0; i < failedMsgArr.length; i++) {
              if(i !== 0){
                if(i === failedMsgArr.length -1){
                  that.paymentModalErrMsg += ' and ';
                }
                else{
                  that.paymentModalErrMsg += ', ';
                }
              }
              that.paymentModalErrMsg += failedMsgArr[i];
            }​
            that.paymentModalErrMsg += '.';
            that.isPaymentModalErr = true;
            document.getElementById("submitPaymentModalBtn").removeAttribute("disabled");
            document.getElementById("reEnterAddrModalBtn").removeAttribute("disabled");
            document.querySelector("#modal-basic-title").scrollIntoView();
          ​}
          else{
            that.userService.updatePaymentInfo(token.id).subscribe(
              success => {
                that.isPaymentModalSuccess = true;
                that.isPaymentModalErr = false;
                document.querySelector("#modal-basic-title").scrollIntoView();
                setTimeout(() => {
                  document.getElementById("reEnterAddrModalBtn").removeAttribute("disabled");
                  that.getUserAccountDetails();
                  that.enterCardModalRef.close();
                }, 3000);
              },
              error => {
                if(error.error.error == 'Cookie tempered'){
                  window.location.href = '/signout';
                  return;
                }
                document.getElementById("submitPaymentModalBtn").removeAttribute("disabled");
                document.getElementById("reEnterAddrModalBtn").removeAttribute("disabled");
                that.paymentModalErrMsg = 'Failed to update. Please try again.';  
                if(error.error){
                  that.paymentModalErrMsg = error.error.error.message;
                }
                that.isPaymentModalErr = true;
                document.querySelector("#modal-basic-title").scrollIntoView();
              }
            );
          }
        });​
      });
    }
  };
  closePaymentForm = function(isPaymentCancel){
    this.showCCForm = false;
    this.isPaymentErr = false;
    this.isPaymentSuccess = false;
    $('#recurly-form').hide();
    this.showConfirmAddr = false;
    this.confirmAddrRadio = null;
    $('#validateAddressBtn').show();
    $('#cancelPaymentBtn').show();
    $('#submitPaymentBtn').hide();
    $('#reEnterAddrBtn').hide();
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
          if (success.data[0].billing_info != null) {
            this.cardNumber = '**** **** **** '+success.data[0].billing_info.payment_method.last_four;
            this.expirationDate = success.data[0].billing_info.payment_method.exp_month +'/'+ success.data[0].billing_info.payment_method.exp_year;
            this.billFirstName = success.data[0].billing_info.first_name;
            this.billLastName = success.data[0].billing_info.last_name;
            this.displayName = success.data[0].billing_info.first_name + ' ' +success.data[0].billing_info.last_name;
            this.cardType = success.data[0].billing_info.payment_method.card_type.toUpperCase();
            this.addressLine1 = success.data[0].billing_info.address.street1;
            this.city = success.data[0].billing_info.address.city;
            this.stateprovince = success.data[0].billing_info.address.region;
            this.countryCode = success.data[0].billing_info.address.country;
            if(this.countryCode == 'US'){
              this.stateprovince = this.usStateMap[success.data[0].billing_info.address.region];
            }
            this.postalCode = success.data[0].billing_info.address.postal_code;
            this.loadStateMap(this.countryCode);
            if(!isPaymentCancel){
              this.updateUserStatusInformation();
            }
          }
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  closePaymentModalForm = function(){
    this.isPaymentFormOpen = false;
    this.isPaymentModalErr = false;
    this.isPaymentModalSuccess = false;
    $('#recurly-modal-form').hide();
    this.showConfirmModalAddr = false;
    this.confirmAddrModalRadio = null;
    $('#validateAddressModalBtn').show();
    $('#cancelPaymentModalBtn').show();
    $('#submitPaymentModalBtn').hide();
    $('#reEnterAddrModalBtn').hide();
  };
  validateAddress = function(){
    let errArr = [];
    if(this.billFirstName == ''){
      errArr.push("First Name​ can't be blank​");
    }
    if(this.billLastName == ''){
      errArr.push("Last Name​ can't be blank​");
    }
    if(this.addressLine1 == ''){
      errArr.push("Address​ can't be blank​");
    }
    if(this.postalCode == ''){
      errArr.push("Postal Code can't be blank​");
    }
    else{
      let usZipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/; //regex for USA zip only
      if(!usZipRegex.test(this.postalCode)){
        errArr.push("Postal Code is invalid​​");
      }
    }
    if(this.city == ''){
      errArr.push("City​ can't be blank​");
    }
    if(errArr.length){
      this.showConfirmAddr = false;
      this.paymentErrMsg = "";
      for (let i = 0; i < errArr.length; i++) {
        if(i !== 0){
          if(i === errArr.length -1){
            this.paymentErrMsg += ' and ';
          }
          else{
            this.paymentErrMsg += ', ';
          }
        }
        this.paymentErrMsg += errArr[i];
      }​
      this.paymentErrMsg += "."
      this.isPaymentErr = true;
      document.querySelector("#paymentNotification").scrollIntoView();
    }
    else{
      this.isPaymentErr = false;
      this.showConfirmAddr = false;
      document.getElementById("validateAddressBtn").setAttribute("disabled", "disabled");
      document.getElementById("cancelPaymentBtn").setAttribute("disabled", "disabled");
      let addrPayload = {
        "AddressLine1": this.addressLine1,
        "AddressLine2": this.addressLine2,
        "City": this.city,
        "Country": "USA",
        "StateProvince": this.stateprovince,
        "PostalCode": this.postalCode
      };
      this.userService.verifyAddress(addrPayload).subscribe(
        success => {
          if(success.Output.length > 0){
            if(success.Output.length == 1){
              let addConfidence = success.Output[0].Confidence;
              if(addConfidence < 80){
                this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
                this.isPaymentErr = true;
                document.getElementById("validateAddressBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
                document.querySelector("#paymentNotification").scrollIntoView();
              }
              else{
                $('#validateAddressBtn').hide();
                $('#cancelPaymentBtn').hide();
                $('#submitPaymentBtn').show();
                $('#reEnterAddrBtn').show();
                document.getElementById("validateAddressBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
                this.verifiedAddrRes = success.Output;
                this.showConfirmAddr = true;
              }
            }
            else{
              $('#validateAddressBtn').hide();
              $('#cancelPaymentBtn').hide();
              $('#submitPaymentBtn').show();
              $('#reEnterAddrBtn').show();
              document.getElementById("validateAddressBtn").removeAttribute("disabled");
              document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
              this.verifiedAddrRes = success.Output;
              this.showConfirmAddr = true;
            }
          }
          else{
            this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
            this.isPaymentErr = true;
            document.getElementById("validateAddressBtn").removeAttribute("disabled");
            document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
            document.querySelector("#paymentNotification").scrollIntoView();
          }
        },
        error => {
          if(error.error.error == 'Cookie tempered'){
            window.location.href = '/signout';
          }
        }
      );
    }
  };
  validateModalAddress = function(){
    let errArr = [];
    if(this.billFirstName == ''){
      errArr.push("First Name​ can't be blank​");
    }
    if(this.billLastName == ''){
      errArr.push("Last Name​ can't be blank​");
    }
    if(this.addressLine1 == ''){
      errArr.push("Address​ can't be blank​");
    }
    if(this.postalCode == ''){
      errArr.push("Postal Code can't be blank​");
    }
    else{
      let usZipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/; //regex for USA zip only
      if(!usZipRegex.test(this.postalCode)){
        errArr.push("Postal Code is invalid​​");
      }
    }
    if(this.city == ''){
      errArr.push("City​ can't be blank​");
    }
    if(errArr.length){
      this.showConfirmModalAddr = false;
      this.paymentModalErrMsg = "";
      for (let i = 0; i < errArr.length; i++) {
        if(i !== 0){
          if(i === errArr.length -1){
            this.paymentModalErrMsg += ' and ';
          }
          else{
            this.paymentModalErrMsg += ', ';
          }
        }
        this.paymentModalErrMsg += errArr[i];
      }​
      this.paymentModalErrMsg += "."
      this.isPaymentModalErr = true;
      document.querySelector("#paymentNotification").scrollIntoView();
    }
    else{
      this.isPaymentModalErr = false;
      this.showConfirmModalAddr = false;
      document.getElementById("validateAddressModalBtn").setAttribute("disabled", "disabled");
      document.getElementById("cancelPaymentModalBtn").setAttribute("disabled", "disabled");
      let addrPayload = {
        "AddressLine1": this.addressLine1,
        "AddressLine2": this.addressLine2,
        "City": this.city,
        "Country": "USA",
        "StateProvince": this.stateprovince,
        "PostalCode": this.postalCode
      };
      this.userService.verifyAddress(addrPayload).subscribe(
        success => {
          if(success.Output.length > 0){
            if(success.Output.length == 1){
              let addConfidence = success.Output[0].Confidence;
              if(addConfidence < 80){
                this.paymentModalErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
                this.isPaymentModalErr = true;
                document.getElementById("validateAddressModalBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentModalBtn").removeAttribute("disabled");
                document.querySelector("#paymentNotification").scrollIntoView();
              }
              else{
                $('#validateAddressModalBtn').hide();
                $('#cancelPaymentModalBtn').hide();
                $('#submitPaymentModalBtn').show();
                $('#reEnterAddrModalBtn').show();
                document.getElementById("validateAddressModalBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentModalBtn").removeAttribute("disabled");
                this.verifiedAddrModalRes = success.Output;
                this.showConfirmModalAddr = true;
              }
            }
            else{
              $('#validateAddressModalBtn').hide();
              $('#cancelPaymentModalBtn').hide();
              $('#submitPaymentModalBtn').show();
              $('#reEnterAddrModalBtn').show();
              document.getElementById("validateAddressModalBtn").removeAttribute("disabled");
              document.getElementById("cancelPaymentModalBtn").removeAttribute("disabled");
              this.verifiedAddrModalRes = success.Output;
              this.showConfirmModalAddr = true;
            }
          }
          else{
            this.paymentModalErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
            this.isPaymentModalErr = true;
            document.getElementById("validateAddressModalBtn").removeAttribute("disabled");
            document.getElementById("cancelPaymentModalBtn").removeAttribute("disabled");
            document.querySelector("#paymentNotification").scrollIntoView();
          }
        },
        error => {
          if(error.error.error == 'Cookie tempered'){
            window.location.href = '/signout';
          }
        }
      );
    }
  };
  selectConfirmedAddress = function(){
    let address = this.verifiedAddrRes[this.confirmAddrRadio];
    this.addressLine1 = address.AddressLine1;
    this.addressLine2 = address.AddressLine2;
    this.city = address.City;
    this.stateprovince = this.usStateMap[address.StateProvince];
    this.postalCode = address.PostalCode;
  };
  selectConfirmedModalAddress = function(){
    let address = this.verifiedAddrModalRes[this.confirmAddrModalRadio];
    this.addressLine1 = address.AddressLine1;
    this.addressLine2 = address.AddressLine2;
    this.city = address.City;
    this.stateprovince = this.usStateMap[address.StateProvince];
    this.postalCode = address.PostalCode;
  };
  resetAddressFields = function(){
    if(this.countryCode == 'US'){
      this.confirmAddrRadio = null;
      $('#validateAddressBtn').show();
      $('#cancelPaymentBtn').show();
      $('#submitPaymentBtn').hide();
      $('#reEnterAddrBtn').hide();
    }
  };
  resetAddressModalFields = function(){
    if(this.countryCode == 'US'){
      this.confirmAddrModalRadio = null;
      $('#validateAddressModalBtn').show();
      $('#cancelPaymentModalBtn').show();
      $('#submitPaymentModalBtn').hide();
      $('#reEnterAddrModalBtn').hide();
    }
  };
  resetCountry = function(){
    if(this.countryCode == 'US'){
      this.resetAddressFields();
    }
    else{
      this.showConfirmAddr = false;
      this.confirmAddrRadio = null;
      $('#validateAddressBtn').hide();
      $('#cancelPaymentBtn').hide();
      $('#submitPaymentBtn').show();
      $('#reEnterAddrBtn').show();
    }
    this.loadStateMap(this.countryCode);
  };
  resetModalCountry = function(){
    if(this.countryCode == 'US'){
      this.resetAddressModalFields();
    }
    else{
      this.showConfirmModalAddr = false;
      this.confirmAddrModalRadio = null;
      $('#validateAddressModalBtn').hide();
      $('#cancelPaymentModalBtn').hide();
      $('#submitPaymentModalBtn').show();
      $('#reEnterAddrModalBtn').show();
    }
    this.loadStateMap(this.countryCode);
  };
  updateUserStatusInformation = function(){
    let userDetails = null;
    this.userService.getUserDeatails().subscribe(
      success => {
        userDetails = success;
        for (let i = 0; i < userDetails.entitlements.length; i++) {
          if (userDetails.entitlements[i].product == 'MIPro') {
            if(userDetails.entitlements[i].status == 'SUSPENDED'){
              if(this.hasPastDue){
                this.isSubsActiveErr = true;
                return;
              }
              this.userService.activateSubscription().subscribe(
                success => {
                  this.userService.getUserStatus().subscribe(
                    success => {
                      window.location.reload();
                    }
                  );
                },
                error => {
                  this.isSubsActiveErr = true;
                }
              );
            }
          }
        }
      }
    );
  };
  cancelSubscription = function(){
    setTimeout(() =>
      this.cancelConfirmModalRef = this.modalService.open(this.cancelConfirmModalEl, { ariaLabelledBy: 'modal-basic-title'})
    );
  };
  confirmCancelSubscription = function(){
    this.isCancelProcessing = true;
    this.isCancelSubsSuccess = false;
    this.isCancelSubsErr = false;
    this.userService.cancelSubscription().subscribe(
      success => {
        this.isCancelProcessing = false;
        this.isCancelSubsSuccess = true;
        this.userService.getUserStatus().subscribe(
          success => {
            window.location.reload();
          }
        );
      },
      error => {
        this.isCancelSubsErr = true;
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  activateSubscription = function(){
    this.isSuspenSubsSuccess = false;
    this.isSuspenSubsErr = false;
    if(this.hasPastDue){
      this.isSuspenSubsErr = true;
      window.scroll(0, 0);
      return;
    }
    this.isSuspenProcessing = true;
    this.userService.activateSubscription().subscribe(
      success => {
        this.isSuspenSubsSuccess = true;
        window.scroll(0, 0);
        this.userService.getUserStatus().subscribe(
          success => {
            window.location.reload();
          },
          error => {
            if(error.error.error == 'Cookie tempered'){
              window.location.href = '/signout';
            }
          }
        );
      },
      error => {
        this.isSuspenProcessing = false;
        this.isSuspenSubsErr = true;
        window.scroll(0, 0);
      }
    );
  };
  valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value.localeCompare(b.value);
  };
}
