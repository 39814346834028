import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ResourceService{
    constructor(private http: HttpClient){}
    getSignupUrl = function () {
        return this.http.get("/api/getsignupurl");
    };
    getRecurlyConfig = function () {
        return this.http.get("/api/recurlyconfig");
    };
    getStates = function (countryCode) {
        return this.http.get("/api/getStates/"+countryCode);
    };
    getCountries = function () {
        return this.http.get("/api/getCountries");
    };
}
