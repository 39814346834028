import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  public showpage:boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private resourceService: ResourceService) {
  };
  ngOnInit() {
    this.userService.getUserDeatails().subscribe(
      success => {
        window.location.href = "/mipro/myaccount";
      },
      error => {
        window.location.href = "/signin";
      }
    );
  };
}
