import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  private startYear = 2020;
  public date: string;

  constructor() { }

  ngOnInit() {
    const currentYear: number = new Date().getFullYear();
    if(this.startYear === currentYear){
      this.date = currentYear.toString();
    }
    else{
      this.date = this.startYear + ',' +currentYear.toString();
    }
  }
}
