import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../service/user.service';
import { ResourceService } from '../../service/resource.service';
declare var require: any;


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    public displayName: string = '';
    public showSignInLink: boolean = true;
    constructor(private resourceService: ResourceService, private userService: UserService) { };

    ngOnInit() {
        let userDetails = null;
        this.userService.getUserDeatails().subscribe(
            success => {
                userDetails = success;
                this.showSignInLink = false;
                this.displayName = userDetails.firstName + " " + userDetails.lastName;
            }
        );
    };
}
