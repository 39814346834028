import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PbdsDatavizModule } from '@precisely/design-system';
import { PbdsHeaderShadowModule } from '@precisely/design-system';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './service/user.service';
import { ResourceService } from './service/resource.service';
import { TableModule } from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { PricingComponent } from './pricing/pricing.component';

const CONFIGURE_STATES = [
  { path: '', component: HomeComponent },
  { path: 'mipro/myaccount', component: MyaccountComponent },
  { path: 'pricing', component: PricingComponent },
  // This is the WILDCARD CATCH-ALL route that is scoped to the entire application.
  // It will catch any request that is not matched by an earlier route definition.
  // Always make sure this is last route
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, HomeComponent, MyaccountComponent, PricingComponent],
  imports: [BrowserModule, AppRoutingModule, FormsModule, PbdsDatavizModule, PbdsHeaderShadowModule, HttpClientModule, RouterModule.forRoot(CONFIGURE_STATES, { relativeLinkResolution: 'legacy' }), NgbModalModule, NgbModule, TableModule, BrowserAnimationsModule],
  providers: [CookieService, UserService, ResourceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
